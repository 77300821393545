<template>
  <div>
    <v-card
      class="shadow2-a"
      loader-height="2"
      :loading="loading || productloading"
      :disabled="loading || productloading"
    >
      <v-card-text class="pb-0">
        <div class="d-flex align-center">
          <v-tooltip bottom content-class="tooltip-bottom">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="shadow-off" icon small @click="$nova.gotoLink({path: '/admin/client'})"><v-icon>mdi-arrow-left</v-icon></v-btn>
            </template>
            <span>Back to projects</span>
          </v-tooltip>
          <v-tooltip bottom content-class="tooltip-bottom">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="shadow-off mr-2" icon small @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
            </template>
            <span>Refresh data</span>
          </v-tooltip>
          <v-spacer></v-spacer>
        </div>
      </v-card-text>
      <v-card-text class="pt-0">
        <div class="d-flex align-center mb-1">
          <div class="title mr-2">{{ data.name }}</div>
        </div>
        <div class="mb-1">
          <v-chip v-if="data.status" small label color="success">Active</v-chip>
          <v-chip v-else small label color="error">Inactive</v-chip>
          <v-chip small label class="ml-2">{{ data.type }}</v-chip>
        </div>
        <div>
          <span class="mr-1"><v-icon>mdi-account-box</v-icon></span>
          <span class="mr-4">{{ data.contactperson || "Not Aavailble" }}</span>
          <span class="mr-1"><v-icon>mdi-email</v-icon></span>
          <span class="mr-4">{{ data.contactemail }}</span>
          <span class="mr-1"><v-icon>mdi-phone</v-icon></span>
          <span class="mr-4">{{ data.contactno }}</span>
          <span class="mr-1"><v-icon>mdi-map-marker</v-icon></span>
          <span class="mr-4">{{ data.address }}</span>
        </div>
      </v-card-text>
      <v-card-text class="pt-0 pb-0">
        <v-tabs v-model="tab" bg-color="primary" height="34">
          <v-tab :key="0" class="text-transform-none justify-start">
            <v-icon class="mr-2">mdi-account-group</v-icon>
            Users
          </v-tab>
          <v-tab :key="1" class="text-transform-none justify-start">
            <v-icon class="mr-2">mdi-apps</v-icon>
            Subscriptions
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item :key="0">
            <div class="d-flex align-center">
              <!-- <div class="pl-2 title border-left-primary font-weight-thin">
                Users
              </div> -->
              <v-btn
                color="primary"
                class=""
                small
                @click="addAccess()"
                ><v-icon>mdi-plus</v-icon>Associate New User</v-btn
              >
            </div>
            <div v-if="userlist.length > 0" class="mt-2">
              <template v-for="(v, k) in userlist">
                <div :key="k" class="hoverable">
                  <div class="d-flex align-center py-1 px-2">
                    <v-avatar
                      size="35"
                      :style="`background-color:${colors[k % 15]}`"
                      class="font-weight-bold mr-2"
                    >
                      {{
                        (((v.user_detail || {}).name || "").split(" ") || [])
                          .slice(0, 2)
                          .map((word) => (word[0] || "").toUpperCase())
                          .join("")
                      }}
                    </v-avatar>
                    <div>
                      <div class="font-weight-bold">{{ (v.user_detail || {}).name }}</div>
                      <div>
                        <span class="mr-1"><v-icon>mdi-email</v-icon></span>
                        <span class="mr-4">{{ (v.user_detail || {}).contactemail }}</span>
                        <!-- <span class="mr-1"><v-icon>mdi-phone</v-icon></span>
                        <span class="mr-4">{{ v.contactno }}</span> -->
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <span v-if="!v.status" small label class="error--text mr-4">Access Disabled</span>
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon small
                          v-bind="attrs"
                          v-on="on"
                          @click="editAccess(v)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      Edit
                    </v-tooltip>
                    <v-tooltip bottom content-class="tooltip-bottom" v-if="v.status">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon small
                          v-bind="attrs"
                          v-on="on"
                          @click="enableDisableAccess(v._id, 'disable', k)"
                          ><v-icon>mdi-close</v-icon></v-btn
                        >
                      </template>
                      Disable
                    </v-tooltip>
                    <v-tooltip bottom content-class="tooltip-bottom" v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon small
                          v-bind="attrs"
                          v-on="on"
                          @click="enableDisableAccess(v._id, 'enable', k)"
                          ><v-icon>mdi-check</v-icon></v-btn
                        >
                      </template>
                      Enable
                    </v-tooltip>
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon small
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="
                            deleteuserdialog = true;
                            deleteuserdata = { ...v };
                          "
                          ><v-icon>mdi-cancel</v-icon></v-btn
                        >
                      </template>
                      Revok Access
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                </div>
              </template>
            </div>
            <div v-else class="title grey--text my-8 text-center">No user added</div>
          </v-tab-item>
          <v-tab-item :key="1">
            <div class="d-flex align-center">
              <!-- <div class="pl-2 title border-left-primary font-weight-thin">
                Users
              </div> -->
              <v-btn
                color="primary"
                class=""
                small
                @click="addSubscriptionDialog()"
                ><v-icon>mdi-plus</v-icon>New Subscription</v-btn
              >
            </div>
            <div v-if="subscriptionlist.length > 0" class="mt-2">
              <template v-for="(v, k) in subscriptionlist">
                <div :key="k" class="hoverable">
                  <div class="d-flex align-center py-1 px-2">
                    <v-avatar
                      size="35"
                      :class="`font-weight-bold mr-2 primary c${((v.product_detail || {}).solution || '').toLowerCase()} white--text`"
                    >
                      {{
                        (((v.product_detail || {}).solution || "").split(" ") || [])
                          .slice(0, 1)
                          .map((word) => (word[0] || "").toUpperCase())
                          .join("")
                      }}
                    </v-avatar>
                    <div>
                      <div class="font-weight-bold">{{ (v.product_detail || {}).name }}</div>
                      <div>
                        <span class="mr-1"><v-icon>mdi-checkbox-marked-outline</v-icon></span>
                        <span class="mr-4">{{ (v.plan_detail || {}).name }}</span>
                        <!-- <span class="mr-1"><v-icon>mdi-phone</v-icon></span>
                        <span class="mr-4">{{ v.contactno }}</span> -->
                      </div>
                    </div>
                    <v-spacer></v-spacer>
                    <div v-if="v.status" class="mr-2 success--text font-weight-bold">
                      Active
                    </div>
                    <div v-else class="mr-2 error--text font-weight-bold">
                      Inactive
                    </div>
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon small
                          v-bind="attrs"
                          v-on="on"
                          @click="editSubscription(v)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      Edit
                    </v-tooltip>
                    <v-tooltip bottom content-class="tooltip-bottom" v-if="v.status">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon small
                          v-bind="attrs"
                          v-on="on"
                          color="error"
                          @click="enableDisableSubscription(v._id, 'disable', k)"
                          ><v-icon>mdi-close-circle</v-icon></v-btn
                        >
                      </template>
                      Disable
                    </v-tooltip>
                    <v-tooltip bottom content-class="tooltip-bottom" v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon small
                          v-bind="attrs"
                          v-on="on"
                          color="success"
                          @click="enableDisableSubscription(v._id, 'enable', k)"
                          ><v-icon>mdi-check-circle</v-icon></v-btn
                        >
                      </template>
                      Enable
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                </div>
              </template>
            </div>
            <div v-else class="title grey--text my-8 text-center">No subscription added</div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <v-dialog v-model="deleteuserdialog" max-width="600" persistent>
      <v-card
        :loading="deleteuserloading"
        :disabled="deleteuserloading"
        loader-height="2"
        class=""
      >
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">Revok User Access</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="deleteuserdialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4">
          <div class="mt-4">
            <div class="mb-2">
              This will revok
              <strong
                >{{ (deleteuserdata.user_detail || {}).name }} ({{
                  (deleteuserdata.user_detail || {}).contactemail
                }})</strong
              >
              user's access from <strong>{{data.name}}</strong> client. Do you want to continue?
            </div>
          </div>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="revokeAccess()">Confirm</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="adduserdialog" max-width="600" persistent>
      <v-card
        :loading="adduserloading"
        :disabled="adduserloading"
        loader-height="2"
        class=""
      >
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="!editId"
            >Associate New User</span
          >
          <span class="subtitle-1 white--text" v-else>Edit User</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="adduserdialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-6">
          <lb-string
            v-model="adduserdata.email"
            label="Enter User Email"
            :error="addusererror.email"
          />
          <div class="body-2 mb-2">Select the appropriate role under each subscribed solution</div>
          <v-row>
            <v-col>
              <v-divider></v-divider>
              <div v-for="(items, i) in productList" :key="i" class="">
                <div class="hoverable px-2 py-1">
                  <div class="font-weight-bold ">
                    {{ items.name }}
                  </div>
                  <div class="error--text" v-if="Object.keys(items.role || {}).length === 0">No roles available</div>
                  <v-radio-group
                    v-else
                    v-model="selected[items._id]"
                    row
                    class="pt-0 mt-0"
                    dense
                    hide-details
                  >
                    <v-radio v-for="(v, i) in items.role" :key="i" :label="v.name" class="my-0" :value="v._id"></v-radio>
                    <v-radio label="None" value="none"></v-radio>
                  </v-radio-group>
                </div>
                <v-divider></v-divider>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="addEditAccess()"
            >Confirm</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addeditsubdialog" max-width="600" persistent>
      <v-card
        :loading="addeditsubloading"
        :disabled="addeditsubloading"
        loader-height="2"
        class=""
      >
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="editsubsid === ''"
            >Add New Subscription</span
          >
          <span class="subtitle-1 white--text" v-else>Edit Subscription</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="addeditsubdialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4">
          <div v-if="!(selectedproduct || {})._id">
            <div class="body-2 mb-2">Select a product from the list below</div>
            <div v-if="possibleproducts.length > 0">
              <template v-for="(v,k) in possibleproducts">
                <div :key="k" class="hoverable cursor-pointer" @click="selectedproduct = v">
                  <div class="pa-1">
                    <v-avatar size="25" :class="`font-weight-bold mr-2 primary c${(v.solution || '').toLowerCase()} white--text`">
                      {{
                        ((v.solution || "").split(" ") || [])
                          .slice(0, 1)
                          .map((word) => (word[0] || "").toUpperCase())
                          .join("")
                      }}
                    </v-avatar>
                    <span>{{ v.name }}</span>
                  </div>
                  <v-divider></v-divider>
                </div>
              </template>
            </div>
            <div v-else class="pt-2">
              No products available to subscribe
            </div>
          </div>
          <div  v-else-if="!(selectedplan || {})._id">
            <div class="mb-2 d-flex align-center">
              <v-btn small icon class="shadow-off mr-2" v-if="editsubsid === ''" @click="selectedproduct = {};selectedplan = {};">
                <v-icon class="">mdi-arrow-left</v-icon>
              </v-btn>
              <span class="body-2">
                Select a plan from the list below for 
              </span>
              <v-chip class="ml-2 white--text" small label :color="`primary c${(selectedproduct.solution || '').toLowerCase()}`">{{selectedproduct.name}}</v-chip>
            </div>
            <div v-if="possibleplans.length > 0">
              <template v-for="(v,k) in possibleplans">
                <div :key="k" class="hoverable cursor-pointer" @click="selectedplan = v">
                  <div class="pa-1">
                    <v-avatar size="25" :class="`font-weight-bold mr-2 primary c${(selectedproduct.solution || '').toLowerCase()} white--text`">
                      {{
                        ((selectedproduct.solution || "").split(" ") || [])
                          .slice(0, 1)
                          .map((word) => (word[0] || "").toUpperCase())
                          .join("")
                      }}
                    </v-avatar>
                    <span>{{ v.name }}</span>
                    <span class="ml-2 caption">({{ v.code }})</span>
                  </div>
                  <v-divider></v-divider>
                </div>
              </template>
            </div>
            <div v-else class="pt-2">
              No plans available in this product
            </div>
          </div>
          <div v-else>
            <div class="d-flex align-center mb-2">
              <v-btn small icon class="shadow-off mr-2" @click="selectedplan = {}">
                <v-icon class="">mdi-arrow-left</v-icon>
              </v-btn>
              <span class="body-2">You have selected the below plan. Do you want to proceed to add new subscription?</span>
            </div>
            <div>
              <span class="font-weight-bold">Selected Product:</span>
              <span class="ml-2">{{selectedproduct.name}}</span>
            </div>
            <div>
              <span class="font-weight-bold">Selected Plan:</span>
              <span class="ml-2">{{selectedplan.name}}</span>
            </div>

          </div>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn :disabled="!((selectedproduct || {})._id && (selectedplan || {})._id)" small color="primary" @click="addEditSubscription()"
            >Confirm</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'

export default {
  name: "clientv2",
  // props: ['props'],
  data: function () {
    return {
      id: "",
      data: {},
      userlist: [],
      subscriptionlist: [],
      existingproductids: [],
      tab: 0,
      subscriptionproducts: [],
      subscriptionplans: [],
      addeditsubdialog: false,
      addeditsubloading: false,
      selectedproduct: {},
      selectedplan: {},
      editsubsid: '',
      possibleproducts: [],
      possibleplans: [],
      loading: false,
      productloading: false,
      addflag: false,
      colors: [],
      deleteuserdialog: false,
      deleteuserloading: false,
      deleteuserdata: {},
      adduserdialog: false,
      adduserloading: false,
      adduserdata: {},
      addusererror: {},
      editflag: false,
      // selectedindex: 0,
      formdata: {},
      errors: {},
      editId: null,
      productList: [],
      selected: {},
      // selectdata: {
      //   "type": [
      //     {name: "Individual", value: "Individual"},
      //     {name: "Company", value: "Company"},
      //     {name: "Firm", value: "Firm"},
      //     {name: "Trust", value: "Trust"},
      //     {name: "Government", value: "Government"},
      //     {name: "Others", value: "Others"}
      //   ],
      // }
    };
  },
  // components: {
  //   // pageHeader,managelist
  // },
  created() {
    this.refreshData();
    this.colors = this.$nova.getColors(15, 0.5);
  },
  activated() {
    this.refreshData();
  },
  mounted() {},
  methods: {
    refreshData() {
      this.id = this.$route.params.id;
      this.errors = {};
      this.addflag = false;
      this.editflag = false;
      this.getData();
      this.getProductListData();
    },
    getData() {
      this.loading = true;
      this.axios
        .post("/v2/client/get/" + this.id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.data = dt.data.data[0] || {};
            // this.$store.commit("setClient", this.data._id);
            return this.axios.post("/v2/userpermission/userlist/"+this.data._id, {
            });
          } else throw new Error(dt.data.message || "Error fetching data");
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.userlist = dt.data.data;
            return this.axios.post("/v2/subscription/subscribed/allsubscriptions/"+this.data._id);
            // console.log(this.userlist);
          } else throw new Error(dt.data.message || "Error reading userlist");
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.subscriptionlist = dt.data.data;
            this.existingproductids = [];
            for (const i of this.subscriptionlist) {
              this.existingproductids.push((i.product_detail || {})._id);
            }
            return this.axios.post("/v2/subscription/subscribed/formdata");
          } else throw new Error(dt.data.message || "Error reading subscription list");
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            console.log(dt.data.data);
            this.subscriptionproducts = (dt.data.data[0] || {}).product || [];
            this.subscriptionplans = (dt.data.data[0] || {}).plan || [];
            console.log(this.subscriptionproducts);
            // let existingproducts = [];
            // for (const i of subscriptionlist) {
            //   existingproducts.push(i.product_detail._id);
            // }
            // let products = (dt.data.data[0] || {}).product || [];
            // let plans = (dt.data.data[0] || {}).plan || [];
            // let newproducts = [];
            // let newplans = [];
            // for (const i of products) {
            //   if(existingproducts.indexOf(i._id) === -1) newproducts.push(i);
            // }
            // for (const i of plans) {
            //   if(newproducts.indexOf(i.product) > -1) newplans.push(i);
            // }
            // this.newsubscriptionproducts = newproducts;
            // this.newsubscriptionplans = newplans;
          } else throw new Error(dt.data.message || "Error reading subscription list");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getProductListData() {
      this.productloading = true;
      this.axios
        .post("/v2/userpermission/getformdata/" + this.id)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.productList = ele.data.data;
            // console.log(this.productList, "productList");
          } else throw new Error(ele.data.message || "Error fetching data");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.productloading = false;
        });
    },
    addSubscriptionDialog() {
      this.selectedproduct = {};
      this.selectedplan = {};
      this.editsubsid = '';
      this.addeditsubdialog = true;
      this.possibleproducts = [];
      for (const i of this.subscriptionproducts) {
        // console.log(i._id, this.existingproductids);
        if(this.existingproductids.indexOf(i._id) === -1 && i.code !== "admin") this.possibleproducts.push(i);
      }
      // this.adduserdata = {};
      // this.addusererror = {};
      // this.selected = {};
      // for (const i of this.productList) {
      //   this.selected[i._id] = "none";
      // }
      // this.adduserdialog = true;
    },
    addAccess() {
      this.adduserdata = {};
      this.addusererror = {};
      this.selected = {};
      for (const i of this.productList) {
        this.selected[i._id] = "none";
      }
      this.adduserdialog = true;
    },
    editAccess(item) {
      this.adduserdata = {...item};
      this.addusererror = {};
      this.selected = {};
      for (const i of this.productList) {
        for (const j of i.role) {
          if((this.adduserdata.role || []).indexOf((j._id || "x")) > -1) {
            this.selected[i._id] = j._id;
            break;
          }
          else this.selected[i._id] = "none";
        }
      }
      this.adduserdialog = true;
    },
    editSubscription(item){
      // console.log(item);
      this.selectedproduct = item.product_detail || {};
      this.selectedplan = item.plan_detail || {};
      this.editsubsid = item._id;
      this.addeditsubdialog = true;
    },
    revokeAccess() {
      this.deleteuserloading = true;
      this.axios
        .post("/v2/userpermission/delete/" + this.deleteuserdata._id, {})
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "User access removed");
            this.deleteuserdialog = false;
            this.refreshData();
          } else throw new Error(dt.data.message || "Error fetching data");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.deleteuserloading = false;
        });
    },
    // provideAccess() {
    //   this.deleteuserloading = true;
    //   this.axios
    //     .post("/v2/client/provideuseraccess/" + this.adduserdata.email, {
    //       client: this.data._id,
    //     })
    //     .then((dt) => {
    //       if (dt.data.status === "success") {
    //         this.$store.commit("sbSuccess", "User access added");
    //         this.adduserdialog = false;
    //         this.refreshData();
    //       } else throw new Error(dt.data.message || "Error fetching data");
    //     })
    //     .catch((err) => {
    //       this.$store.commit("sbError", err.message || err || "Unknown error!");
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.deleteuserloading = false;
    //     });
    // },
    addEditAccess() {
      this.adduserloading = true;
      let url = "/v2/userpermission/add";
      let type = "add";
      if(this.adduserdata._id){
        type = "edit";
        url = "/v2/userpermission/edit/" + this.adduserdata._id;
      }
      let accessdata = [];
      for (const k in this.selected) {
        if (Object.hasOwnProperty.call(this.selected, k)) {
          const el = this.selected[k];
          if(el !== 'none') accessdata.push(el);
        }
      }
      this.axios
        .post(url, {
          data: {role: accessdata, email: this.adduserdata.email, client : this.data._id},
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            if(type === "add") this.$store.commit("sbSuccess", "User access added");
            else this.$store.commit("sbSuccess", "User access updated");
            this.adduserdialog = false;
            this.refreshData();
          } else throw new Error(dt.data.message || "Error fetching data");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.adduserloading = false;
        });
    },
    addEditSubscription() {
      this.addeditsubloading = true;
      let url = "/v2/subscription/subscribed/add/";
      let type = "add";
      let newdata = {product: this.selectedproduct._id, plan: this.selectedplan._id};
      if(this.editsubsid){
        type = "edit";
        url = "/v2/subscription/subscribed/edit/" + this.editsubsid;
      }else newdata.client = this.data._id;
      let accessdata = [];
      for (const k in this.selected) {
        if (Object.hasOwnProperty.call(this.selected, k)) {
          const el = this.selected[k];
          if(el !== 'none') accessdata.push(el);
        }
      }
      this.axios
        .post(url, {data: newdata})
        .then((dt) => {
          if (dt.data.status === "success") {
            if(type === "add") this.$store.commit("sbSuccess", "Subscription added");
            else this.$store.commit("sbSuccess", "Subscription updated");
            this.addeditsubdialog = false;
            this.refreshData();
          } else throw new Error(dt.data.message || "Error fetching data");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.addeditsubloading = false;
        });
    },
    enableDisableAccess(id, action, k) {
      this.loading = true;
      let url = "/v2/userpermission/disable/";
      if(action === "enable"){
        url = "/v2/userpermission/enable/"
      }
      this.axios
        .post(url + id, {})
        .then((dt) => {
          if (dt.data.status === "success") {
            if(action === "enable") {
              this.$store.commit("sbSuccess", "User access enabled");
              this.userlist[k].status = true;
            }
            else{
              this.$store.commit("sbSuccess", "User access disabled");
              this.userlist[k].status = false;
            } 
          } else throw new Error(dt.data.message || "Error performing action");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enableDisableSubscription(id, action, k) {
      this.loading = true;
      let url = "/v2/subscription/subscribed/disable/";
      if(action === "enable"){
        url = "/v2/subscription/subscribed/enable/"
      }
      this.axios
        .post(url + id, {})
        .then((dt) => {
          if (dt.data.status === "success") {
            if(action === "enable") {
              this.$store.commit("sbSuccess", "Subscription enabled");
              this.subscriptionlist[k].status = true;
            }
            else{
              this.$store.commit("sbSuccess", "Subscription disabled");
              this.subscriptionlist[k].status = false;
            } 
          } else throw new Error(dt.data.message || "Error performing action");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {},
  watch: {
    selectedproduct: function(d){
      // this.selectedplan = {};
      this.possibleplans = [];
      if((d || {})._id){
        for (const i of this.subscriptionplans) {
          if(i.product === (d || {})._id) this.possibleplans.push(i)
        }
      }
    }
  },
};
</script>
